<template>
  <nav class="w-full py-4 border-t border-b bg-gray-100">
      <div class="block sm:hidden">
          <a
              href="#"
              class="md:hidden text-base font-bold uppercase text-center flex justify-center items-center"
              @click="open = !open"
          >
              <div class="mt-1">
                {{ config.accountname }}
              </div>
              <button class="text-gray-500 w-10 h-10 relative focus:outline-none">
                  <span class="sr-only">{{ $t('openMenu') }}</span>
                  <div class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" :class="{'rotate-45': open,' -translate-y-1.5': !open }"></span>
                      <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" :class="{'opacity-0': open }"></span>
                      <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" :class="{'-rotate-45': open, ' translate-y-1.5': !open}"></span>
                  </div>
              </button>
          </a>
      </div>
      <div :class="open ? 'block': 'hidden'" class="w-full flex-grow sm:flex sm:items-center sm:w-auto">
          <div class="w-full container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm font-bold uppercase mt-0 px-6 py-2">
              <template v-if="$auth.isAuthenticated">
                <router-link class="hover:bg-gray-400 rounded py-2 px-4 mx-2" to="/schedule">{{ $t('myTickets') }}</router-link>
                <router-link class="hover:bg-gray-400 rounded py-2 px-4 mx-2" to="/waitinglist" v-if="config.waitinglist.active && this.$auth.contactinfo.hasWaitinglist">{{ $t('myWaitinglist') }}</router-link>
                <router-link class="hover:bg-gray-400 rounded py-2 px-4 mx-2" to="/credit" v-if="config.credit.active">{{ $t('personalCredit') }}</router-link>
                <router-link class="hover:bg-gray-400 rounded py-2 px-4 mx-2" to="/loyalty" v-if="config.loyalty.active && this.$auth.contactinfo.hasLoyalty" >{{ $t('localtyCards') }}</router-link>
                <router-link class="hover:bg-gray-400 rounded py-2 px-4 mx-2" to="/info">{{ $t('personalData') }}</router-link>
                <a v-if="config.salesUrl" class="hover:bg-gray-400 rounded py-2 px-4 mx-2" target="_blank" :href="config.salesUrl">{{ $t('buyTickets') }}</a>
                <router-link class="hover:bg-gray-400 rounded py-2 px-4 mx-2 block sm:hidden" to="/logout">{{ $t('logOut') }}</router-link>
              </template>
          </div>
      </div>
  </nav>
</template>
<script>
const R = require("ramda")
import { mapGetters } from 'vuex'

import store from '../store';

function loadLocaleMessages() {
  const messages = {
    en: { 
        openMenu: 'Open menu',
        myTickets: 'My tickets',
        myWaitinglist: 'My waitinglist',
        personalCredit: 'Personal credit',
        localtyCards: R.path(['state', 'config', 'nav', 'loyaltyCards', 'en'], store) || 'Loyalty cards',
        personalData: 'Personal data',
        buyTickets: 'Buy tickets',
        logOut: 'Log out',
    },
    nl: {
        openMenu: 'Open menu',
        myTickets: 'Mijn tickets',
        myWaitinglist: 'Mijn wachtlijst',
        personalCredit: 'Persoonlijk tegoed',
        localtyCards: R.path(['state', 'config', 'nav', 'loyaltyCards', 'nl'], store) || 'Voordeelkaarten',
        personalData: 'Mijn gegevens',
        buyTickets: 'Bestel tickets',
        logOut: 'Uitloggen',
    },
  }
  return messages;
}

export default {
  name: 'NavBar',
  data() {
    return {
      open: false,
    }
  },
  i18n: {
    messages: loadLocaleMessages(),
  },
  computed: {
      ...mapGetters(["config"]),
  },  
  methods: {},
}
</script>
<style>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
a:hover {
  cursor: pointer;
}
#nav a.router-link-exact-active {
  color: #FFCC00;
}
</style>