<template>
    <div id="tmplugin_basket">
        <a title="Winkelmand" :href="config.basketUrl" target="_blank">
            <svg viewBox="0 0 1300 1000" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs></defs>
                <g id="tmplugin_basket_icon" class="tmplugin_basket_icon_empty">
                    <path d="M871.9,360.7H607.5v7.4c0,59.3-48.2,107.5-107.5,107.5c-59.3,0-107.5-48.2-107.5-107.5v-7.4H128.1C63,360.7,10,413.7,10,478.8v39c0,51.5,33.2,95.5,79.3,111.5l25.5,250c7.4,58.9,57.8,103.3,117.2,103.3h536c59.4,0,109.7-44.4,117.2-103.3l25.5-250c46.1-16.1,79.3-60,79.3-111.5v-39C990,413.7,937,360.7,871.9,360.7z M357.2,814.8c0,22.5-18.3,40.8-40.8,40.8s-40.8-18.3-40.8-40.8V653.7c0-22.5,18.3-40.8,40.8-40.8c22.5,0,40.8,18.3,40.8,40.8V814.8z M540.8,814.8c0,22.5-18.3,40.8-40.8,40.8s-40.8-18.3-40.8-40.8V653.7c0-22.5,18.3-40.8,40.8-40.8s40.8,18.3,40.8,40.8V814.8L540.8,814.8z M724.3,814.8c0,22.5-18.3,40.8-40.8,40.8c-22.5,0-40.8-18.3-40.8-40.8V653.7c0-22.5,18.3-40.8,40.8-40.8s40.8,18.3,40.8,40.8V814.8L724.3,814.8z"/><path d="M500,432.4c35.5,0,64.3-28.8,64.3-64.3V81.6c0-35.5-28.8-64.3-64.3-64.3c-35.5,0-64.3,28.8-64.3,64.3v286.5C435.7,403.6,464.5,432.4,500,432.4z" fill-rule="nonzero"/>
                    <g id="tmplugin_basket_badge" transform="translate(700,0)" style="display:none;">
                        <circle id="tmplugin_basket_badge_background" fill-rule="nonzero" cx="300" cy="300" r="300"></circle>
                        <text id="tmplugin_basket_badge_nbr" font x="300" y="300" font-size="360" text-anchor="middle" alignment-baseline="central">0</text>
                    </g>
                </g>
            </svg>
        </a>
    </div>
</template>

<style scoped>
#tmplugin_basket {
    width: 40px;
}

#tmplugin_basket_icon {
    @apply fill-white;
}
#tmplugin_basket_icon.tmplugin_basket_icon_empty {
    @apply fill-white;
}

#tmplugin_basket_badge_background {
    @apply fill-white;
}
#tmplugin_basket_badge_nbr {
    @apply fill-primary;
}
</style>

<script>
import { mapGetters } from 'vuex'

function tmplugin_basket_refresh(url) {
    var xhttp = new XMLHttpRequest();
    xhttp.withCredentials = true;
    xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            var basket = JSON.parse(xhttp.responseText);
            var cnt = basket.nbroftickets;
            var badge = document.getElementById("tmplugin_basket_badge");
            if (badge) {
                var c = badge.getAttribute("class");
                if (c == "tmplugin_basket_count_all") {
                    cnt = basket.nbroftickets + basket.nbrofproducts;
                } else if (c == "tmplugin_basket_count_products") {
                    cnt = basket.nbrofproducts;
                }
            }

            var icon, nbr;

            if (cnt==0) {
                if (badge) {
                    badge.style.display="none";
                }

                icon = document.getElementById("tmplugin_basket_icon");
                if (icon) {
                    icon.classList.add("tmplugin_basket_icon_empty");
                }

                nbr = document.getElementById("tmplugin_basket_badge_nbr");
                if (nbr) {
                    nbr.innerHTML = 0;
                }
            } else {
                if (badge) {
                    badge.style.display=null;
                }

                icon = document.getElementById("tmplugin_basket_icon");
                if (icon) {
                    icon.classList.remove("tmplugin_basket_icon_empty");
                }

                nbr = document.getElementById("tmplugin_basket_badge_nbr");
                if (nbr) {
                    nbr.innerHTML = cnt;
                }
            }
        }
    };
    xhttp.open("GET", url, true);
    xhttp.send();
}

export default {
  data() {
    return {
    }
  },
  computed: {
      ...mapGetters(["config"]),
  },  
  props: {
  },
  methods: {
  },
  async mounted () {
    const pluginUrl = this.config.pluginUrl;
    var el = document.createElement('script');
    el.src = 'https://apps.ticketmatic.com/widgets/plugin/pluginjs';
    el.type = 'text/javascript';
    el.async = 'true';
    el.onload = el.onreadystatechange = function() {
        tmplugin_basket_refresh(pluginUrl);
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(el, s);
  },  
}


</script>